import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-secondary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "reporting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reporting",
        "aria-label": "reporting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reporting`}</h2>
    <p>{`Report Cards`}</p>
    <p>{`Report cards are distributed four times a year. Progress reports are distributed at the halfway point of each semester, approximately in November and April. Semester-end reports are distributed at the end of each semester, approximately in January and in June.`}</p>
    <p>{`Quarter report cards provide an interim set of comments and provisional results on achievement. These marks are not put on any transcript. They are designed to indicate the current level of achievement of each student. Students in the MYP do not receive an overall grade for their courses in the first quarter; they are marked on criteria only; this is because not all criteria have been assessed that early in the year. DP students receive a holistic / summative grade each quarter.`}</p>
    <p>{`Semester-end report card marks are entered onto transcripts in Grades 9-12.`}</p>
    <p>{`In addition to these formal reports, we encourage parents and students to speak frequently to teachers regarding student progress. Parents and students can also check student progress on MyPSI. We expect teachers to contact parents and administration regarding students who are at risk of failing any course.`}</p>
    <p><strong parentName="p">{`1-7 Scale`}</strong></p>
    <p>{`Students are assessed on a scale of 1-7 in alignment with IB assessment protocols. In both MYP and DP, teachers use specific criteria to assess student progress. In MYP particularly, rubrics for each subject area describe student achievement. The descriptors offered below should be taken as a general guide. Students and parents should speak directly to teachers for more specific information about grading procedures and criteria.`}</p>
    <table>
    <colgroup>
        <col style={{
          "width": "15%"
        }} />
        <col style={{
          "width": "84%"
        }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><p><strong>7</strong> Excellent</p></th>
        <th>The student shows a thorough understanding of the required knowledge and skills. Work thus far has been of a high standard. The student sustains originality and insight at most times.</th>
    </tr>
    <tr className="odd">
        <th> <p><strong>6</strong> Very Good</p></th>
        <th> <p>The student has a very good level of understanding of the required knowledge and skills thus far and work of a high quality is usually produced. The student generally demonstrates originality and insight.</p></th>
    </tr>
    <tr className="header">
        <th> <p><strong>5</strong> Good</p></th>
        <th> <p>The student has a good understanding of the required knowledge and has shown some evidence of analysis, evaluation and synthesis.</p></th>
    </tr>
    <tr className="odd">
        <th> <p><strong>4</strong> Satisfactory</p></th>
        <th> <p>The student has a good general understanding of the knowledge and some awareness of the necessary skills, occasionally showing some evidence of analysis, synthesis and evaluation.</p></th>
    </tr>
    <tr className="header">
        <th> <p><strong>3</strong> Limited</p></th>
        <th> <p>The student has a limited understanding of the required knowledge and skills. The student requires some support to apply them.</p></th>
    </tr>
    <tr className="odd">
        <th> <p><strong>2</strong> Very limited</p></th>
        <th> <p>The student has difficulty in understanding the required knowledge and skills and requires considerable support to apply them.</p></th>
    </tr>
    <tr className="header">
        <th> <p><strong>1</strong> Minimal</p></th>
        <th> <p>The student has difficulty understanding the required knowledge and skills and is unable to apply them even with support.</p></th>
    </tr>
    <tr className="odd">
        <th> <p>0</p></th>
        <th> <p>The student has produced work that fails to meet any of the criterion levels or has not turned in sufficient work to be assessed on a given criterion.</p></th>
    </tr>
    <tr className="header">
        <th>No Grade (NG) Incomplete (I)</th>
        <th> <p>The student has not produced sufficient work to receive one of the above descriptors. This mark is reserved for students who have special circumstances (e.g., late arrival into the programme or extended excused absences). The expectation is that the work will be completed and a grade assigned at a later date.</p></th>
    </tr>
    </thead>
    <tbody></tbody>
    </table>
    <p><strong parentName="p">{`Application and Attitude Grades`}</strong></p>
    <p>{`The aim of the IB is to inspire the ‘spirit of the life-long learner.’ At PSI, we believe that an important first step in that process is having the student take greater control over assessment in order to maximise success. We encourage students to do the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Understand the expectations of each teacher and course`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Understand their own learning style`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Engage actively in learning and assessment`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Develop subject-based skills`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`See ‘getting stuck’ as a positive opportunity to advance learning`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Take intellectual risks`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Reflect on what they have learnt`}</p>
      </li>
    </ul>
    <p>{`The Application and Attitude grades reflect the students’ effort and engagement with classroom learning as active and responsible learners. These marks are based on:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Completing tasks punctually`}</strong>{`: The student completes homework, assessments and group projects on time. Students who are absent submit the work by e-mail or contact the teacher ahead of time to discuss any difficulties they have meeting the deadline.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Working independently and taking personal responsibility`}</strong>{`: The student is organised, comes prepared for class, completes homework, manages time effectively and shows initiative when appropriate. The student is reflective about his or her actions and tries to learn from mistakes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Demonstrating commitment and a positive attitude`}</strong>{`: The student makes positive contributions in class, works diligently at all times, shows motivation, and enthusiasm towards his/her studies. The student also shows perseverance in the face of difficulty.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Demonstrating respect for self`}</strong>{`: The student has good attendance and punctuality to school and to each class. S/he takes responsibility for his/her actions, behaves in a responsible and mature manner, and demonstrates integrity in his/her work.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Working respectfully and productively in whole and small groups`}</strong>{`: The student is courteous, works collaboratively, and shows respect for the learning environment and everyone’s right to learn.`}</p>
      </li>
    </ul>
    <p>{`To achieve the highest grades, students should be conscientious in all of the above areas on a consistent basis.`}</p>
    <p>{`Report Card Descriptors for Application and Attitude Grades`}</p>
    <p><strong parentName="p">{`A`}</strong>{` (Excellent): The student is consistently conscientious, demonstrating an excellent attitude towards his/her studies`}</p>
    <p><strong parentName="p">{`B`}</strong>{` (Good): The student demonstrates a good attitude and level of application most of the time`}</p>
    <p><strong parentName="p">{`C`}</strong>{` (Satisfactory): The student applies himself / herself to a satisfactory degree`}</p>
    <p><strong parentName="p">{`D`}</strong>{` (Limited): The student needs to demonstrate greater application and engagement towards his / her studies`}</p>
    <p><strong parentName="p">{`E`}</strong>{` (Very Limited): The student does not meet the basic requirements of the tasks set`}</p>
    <h2 {...{
      "id": "awards-and-recognitions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#awards-and-recognitions",
        "aria-label": "awards and recognitions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Awards And Recognitions`}</h2>
    <p>{`Each semester, the Secondary School holds an Honours Assembly to acknowledge outstanding work by students. At both assemblies, we recognise Honours and High Honours students, as well as Application and Attitude recipients. At the June assembly, we also recognise individual student achievement in each of the areas of the learner profile and award several school-wide awards for outstanding effort and achievement. All school-wide awards are awarded by teacher vote.`}</p>
    <p><strong parentName="p">{`Honour Role`}</strong></p>
    <p>{`Students receive Honour Roll recognition if they achieve the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Grades of 5, 6 or 7 in every course`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`An A or B in TOK for Grade 11 and 12`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A satisfactory or above in ungraded courses (e.g., EAL)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satisfactory completion of DP CAS / MYP Service and Action requirements`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satisfactory for works in progress and 5 or above on Personal Project / A or B on Extended Essay for completed work`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`No Attitude and Application grade below a B`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`High Honour Roll`}</strong></p>
    <p>{`Students receive High Honour Roll recognition if they achieve the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Grades of 6 or 7 in every course`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For DP students: An overall score of 36, which can be a combination of 5s, 6s and 7s`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`An A or B in TOK for Grade 11 and 1`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A satisfactory or above in ungraded courses (e.g., EAL)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satisfactory completion of DP CAS / MYP Service and Action requirements`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Satisfactory for work in progress and 6 or above on Personal Project or an A or B on Extended Essay for completed work`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`No Attitude and Application grade below a B`}</p>
      </li>
    </ul>
    <p>{`NOTE: In the first quarter of a student’s move from Standard to Extended Maths, from Language Acquisition to Language & Literature, from EAL to another Language Acquisition course or up a phase in MYP Language Acquisition, the School accepts a 4 in that subject for Honours and a 5 in that subject for High Honours. However, the attitude grade still counts towards the honours certificate.`}</p>
    <p><strong parentName="p">{`Application and Attitude Grade Awards`}</strong></p>
    <p>{`At the end of each semester, students who do not make the honour roll but achieve only A and B marks in this category receive a certificate that recognises sustained commitment and a positive attitude regardless of overall marks.`}</p>
    <p><strong parentName="p">{`Students with 20 or more unexcused absences cannot earn above a C overall in Application and Attitude and are therefore ineligible for Honours or High Honours awards.`}</strong></p>
    <p><strong parentName="p">{`Attendance Awards`}</strong></p>
    <p>{`PSI recognises students who have a combined record of three or fewer absences and five or fewer times late to school. Students must meet both criteria to receive an Attendance Award. School-related absences (e.g., CEESA tournaments, MUN, etc.) are not counted in determining attendance records.`}</p>
    <p><strong parentName="p">{`Learner Profile Awards`}</strong></p>
    <p>{`Each month, the Secondary School focuses on a different learner profile trait. Students and staff can nominate students who exemplify that trait and can provide examples of why that is so. Awards are given out at the monthly assemblies.`}</p>
    <p><strong parentName="p">{`Departmental Awards`}</strong></p>
    <p>{`Each department nominates one student each for Grades 6 - 8, 9 - 10 and 11 - 12. These are not necessarily the highest achieving students in classes but the ones who most consistently demonstrate one or more learner profile traits. Awards are given at the end-of-year assembly.`}</p>
    <p><strong parentName="p">{`Roman Jacobi Award`}</strong></p>
    <p>{`Named for one of the School’s founders, this award is presented to a Grade 6 – 10 male student who has demonstrated outstanding effort and achievement in the IB Middle Years Programme.`}</p>
    <p><strong parentName="p">{`Lady Claire Asquith Award`}</strong></p>
    <p>{`Named for one of the School’s founders, this award is presented to a Grade 6 – 10 female student who has demonstrated outstanding effort and achievement in the IB Middle Years Programme.`}</p>
    <p><strong parentName="p">{`Rowland Jepson Award`}</strong></p>
    <p>{`Named for the School’s first IB Diploma coordinator, this award is presented to a student of either gender in Grade 11 or 12 for outstanding effort and achievement in the IB Diploma Programme.`}</p>
    <p><strong parentName="p">{`Luis and Alexandra Baqueriza Award`}</strong></p>
    <p>{`Named for two of the School’s founders, this award is given to a student of any grade level (6-12) or gender for outstanding commitment to community service.`}</p>
    <h2 {...{
      "id": "support-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#support-services",
        "aria-label": "support services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Support Services`}</h2>
    <p>{`In addition to recognising and rewarding excellence, PSI provides on-going support for students of all abilities.`}</p>
    <p><em parentName="p">{`Learning Support (LS)`}</em></p>
    <p>{`PSI employs a full time LS teacher and two teaching assistants for Grades 6 - 12 to assist students with mild to moderate learning needs. The LS teacher supports students in need of academic assistance or programme accommodations. The LS teacher also works with the subject teachers to differentiate instruction and assignments, and model teaching strategies. The level of intervention will be determined by the student’s need as identified through formal and informal assessments, classroom observations, and feedback from parents and staff.`}</p>
    <p><em parentName="p">{`English as an Additional Language (EAL)`}</em></p>
    <p>{`Students with limited English receive support through the EAL programme. Instead of taking French, Russian, Spanish or Ukrainian, EAL students receive five additional periods of English per week. These classes focus on helping the student develop his / her academic English. In addition, the EAL teachers work with classroom teachers and sometimes attend other classes to provide additional support. Students are placed in this programme based on a combination of teacher recommendation, samples of work and testing.`}</p>
    <p><em parentName="p">{`School Counsellor`}</em></p>
    <p>{`The Secondary School counsellor works with students in a variety of ways. The counsellor provides assistance with college applications and placement (including some college testing). She works with Homeroom teachers in developing a curriculum for pastoral issues ranging from substance abuse to bullying, from study skills to social skills. In addition, she is available for small group or individual personal counselling. These sessions are confidential.`}</p>
    <h2 {...{
      "id": "at-risk-interventions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#at-risk-interventions",
        "aria-label": "at risk interventions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`At Risk Interventions`}</h2>
    <p>{`At each reporting period, PSI identifies students who are receiving a 0, 1, 2 or no grade in any classes. (A grade of 3 is a passing grade; any grade below 3 is a failing grade.) Individual teachers contact parents to discuss plans to support that child. For students failing two or more classes, the principal sends letters to parents asking to meet and set up a contract.`}</p>
    <p><strong parentName="p">{`Transcript Reviews`}</strong></p>
    <p>{`Students in Grades 9-12 are particularly at risk if they fail courses, as this may impact their ability to graduate. High school transcripts are reviewed every semester. The school contacts parents of any student who does not have the appropriate number of credits toward graduation. These students are also put on a contract that is reviewed regularly.`}</p>
    <p><strong parentName="p">{`Daily or Weekly Check-Ins`}</strong></p>
    <p>{`Students who are struggling academically or behaviourally may be closely monitored for a limited period of time. Students on a daily check in system identify up to four goals for themselves. They must have teachers sign off on those goals each day. That report is submitted to an administrator or the counsellor who scans it and sends it to parents. Students on a weekly check in receive a comment each week from each teacher. This Google document form is sent home to parents and to the student. Students who do not improve as a result of these supports may be placed on an academic or behavioural contract.`}</p>
    <p><strong parentName="p">{`Academic Contracts`}</strong></p>
    <p>{`Students who are failing or missing grades for two or more classes in a marking period are placed on an Academic Contract. The principal meets with the parents and the student, outlines what areas need focus, and works with them to draw up a plan for student progress. Parents, students and the school are all assigned roles to support the student, and progress is checked at least monthly. Students are generally placed on academic contracts for only one year. Students who fail to make progress beyond that period are reviewed by the administration to determine if further support is possible or if exit from the school is a better option for the student.`}</p>
    <p><strong parentName="p">{`Behavioural Contracts`}</strong></p>
    <p>{`Students who engage in repeated acts of misconduct are placed on a Behavioural Contract. As with the Academic Contract, the principal meets with the student and the parents to draw up the specific guidelines for behaviour and assigns roles to parents, students and the school for supporting the student. Students are generally placed on behavioural contracts for one year, after which time the administration reviews their overall record to determine if students can continue at PSI and under what circumstances.`}</p>
    <p><strong parentName="p">{`Attendance Contracts`}</strong></p>
    <p>{`Students who have 20 or more absences and / or 20 or more tardies - either excused or unexcused - may be placed on an Attendance Contract. As with other contracts, this is an agreement among administration, students and parents. The contract lasts for one calendar year, after which the contract may be ended or extended, or the student can be held back a year or asked not to return for the following school year.`}</p>
    <h2 {...{
      "id": "panther-eca-sports",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#panther-eca-sports",
        "aria-label": "panther eca sports permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Panther, ECA, Sports`}</h2>
    <h3 {...{
      "id": "panther-athletics-academics-extracurricular-activities--ecas-sports-activity-programme--saps-and-trips",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#panther-athletics-academics-extracurricular-activities--ecas-sports-activity-programme--saps-and-trips",
        "aria-label": "panther athletics academics extracurricular activities  ecas sports activity programme  saps and trips permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Panther Athletics/ Academics, Extracurricular Activities ( Eca’s), Sports Activity Programme ( Sap’s) And Trips`}</h3>
    <p><strong parentName="p">{`Athletics Panthers`}</strong></p>
    <p>{`PSI is a proud member of the Central and Eastern European Schools Association (CEESA) as well as a league of Kyiv International Schools. Teams compete both locally and throughout the CEESA region.`}</p>
    <p>{`The Athletic Director organises sign up via CHQ (online program) for students before each season begins. Middle School (MS) teams train and compete on Tuesdays and Thursdays, while High School (HS) teams train and compete on Mondays, Wednesdays and Fridays. Middle and high school combined teams train on Monday, Wednesday and Thursdays. Students are asked to commit to all practices, games and tournaments when signing up/trying out for a team.`}</p>
    <p>{`For CEESA tournaments, the cost of the flight is borne by the parents. As per CEESA regulations, students are housed with families from the host school for the duration of the tournament, which allows great friendships to be made.`}</p>
    <p>{`Students must be in good academic and behavioural standing before they are allowed to participate in games or tournaments. Students may be pulled from the roster at any time, including for tournament participation, if they are not meeting the academic standards set for them.`}</p>
    <table><colgroup><col style={{
          "width": "33%"
        }} /><col style={{
          "width": "33%"
        }} /><col style={{
          "width": "33%"
        }} /></colgroup><thead><tr className="header"><th><blockquote><p>Season 1</p><p>(August – November)</p></blockquote></th><th><blockquote><p>Season 2</p><p>(December-March)</p></blockquote></th><th><blockquote><p>Season 3</p><p>(March-May)</p></blockquote></th></tr><tr className="odd"><th><blockquote><p>MS Boys and Girls Tennis</p></blockquote></th><th><blockquote><p>MS Boys and Girls Basketball</p></blockquote></th><th><blockquote><p>MS Boys and Girls Volleyball</p></blockquote></th></tr><tr className="header"><th><blockquote><p>MS Boys and Girls Soccer</p></blockquote></th><th><blockquote><p>HS Boys and Girls Basketball</p></blockquote></th><th><blockquote><p>HS Boys and Girls Volleyball</p></blockquote></th></tr><tr className="odd"><th><blockquote><p>HS Boys and Girls Soccer</p></blockquote></th><th><blockquote><p>MS Boys and Girls Table Tennis</p></blockquote></th><th><blockquote><p>MS/HS Boys and Girls Futsal Soccer</p></blockquote></th></tr><tr className="header"><th><blockquote><p>MS/HS Boys and Girls Cross Country Running</p></blockquote></th><th><blockquote><p>MS/HS Boys and Girls Swimming</p></blockquote></th><th><blockquote><p>MS/HS Boys and Girls Track &amp; Field</p></blockquote></th></tr></thead><tbody></tbody></table>
    <p>{`Please note that the availability of these sports is based on numbers of students participating.`}</p>
    <p>{`Panther Academics with traveling teams:`}</p>
    <p>{`As a member of CEESA, students may participate in a range of activities and may also attend tournaments at schools around our region.`}</p>
    <p>{`CEESA Knowledge Bowl (MS / HS) ISTA Drama Festival (MS / HS) Destination Imagination (MS)`}</p>
    <p>{`Robotics (MS / HS) Math Counts (MS / HS) Model United Nations (MS / HS)`}</p>
    <p><strong parentName="p">{`Extra Curricular Activities (ECAs)`}</strong></p>
    <p>{`The ECA coordinator organises three seasons of the ECA programme each year. All activities are included in the school fees.`}</p>
    <p>{`In addition, students may choose activities from a wide range of choices that change each year in the ECA (Extra Curricular Activities) and or SAP (Sports Activity Programme) Past activities have included:`}</p>
    <p>{`Maths Club Mother Tongue Language Lessons Climbing`}</p>
    <p>{`Pens of PSI Plays/Musicals Chess`}</p>
    <p>{`Stock Market Club Yearbook Swimming`}</p>
    <p>{`We encourage parents to offer activities that support the educational and extracurricular goals of PSI. Any parent who has special skills to offer and would be willing to organise an after-school activity can contact the ECA Coordinator.`}</p>
    <p>{`Before each season begins, students can see a list of available activities. They select which ones they want to do, and complete an online registration (CHQ). Students who join the school mid-season may be able to join some activities with the permission of the ECA coordinator and the teacher leading that activity.`}</p>
    <p>{`If any of the activities are cancelled due to weather conditions or other school events, parents are informed. It is important to arrange a pick-up point to meet your child immediately after the activity is over. If a student is absent from school for any reason, s/he may not attend after-school activities on that day.`}</p>
    <p><strong parentName="p">{`Field and Day Trips`}</strong></p>
    <p>{`Throughout the year, students may leave campus with their teachers to engage in off-site activities related to the curriculum or to community and service projects. We consider these trips to be an important part of our overall school programme, as they extend learning, provide real-life applications, and develop in students a greater appreciation of their host country and host city. Teachers notify parents when such trips are taken, and require parent written permission for students to participate.`}</p>
    <p><strong parentName="p">{`Week Without Walls`}</strong></p>
    <p>{`Each year, all students in Grades 6-12 leave Kyiv for one week to engage in the annual Week Without Walls. These trips are considered a mandatory part of the school programme. They are an important part of the school’s curriculum and include assessments that are part of the first quarter reports. Students engage in a range of activities that promote social relationships, leadership, and have specific ties to parts of their grade level curriculum. Upon return, each grade level prepares a presentation for a Week Without Walls assembly. Whilst the trips usually occur in September, the 2020 - 2021 trips will take place in May. This is a response to the recent pandemic and challenges with world travel.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      